
@import '~@/assets/sass/design.sass'

.menuItem
  position: relative
  z-index: $z-dropdown
.submenu
  padding: 0
  list-style: none

@include desktop
  .submenu
    position: absolute
    top: 50%
    left: 65%
    z-index: $z-dropdown
    min-width: 100%
    padding: $base-spacing
    background: $c-acc-green
    border: 1px solid transparentize($c-black, 0.9)
    border-radius: 6px
    box-shadow: $base-shadow
    transform: scale(0) translateY(-50%)
    transition: transform .3s ease-in-out
    &Item
      &:hover
        background: transparentize($c-black, 0.92)
    &::before
      position: absolute
      top: 50%
      left: -10px
      content: ''
      border-top: 10px solid transparent
      border-bottom: 10px solid transparent
      border-right:10px solid $c-acc-green
      transform: translateY(-50%)

  .menuItem
    &:hover
      .submenu
        transform: scale(1) translateY(-50%)
