@import '~@/assets/sass/design'

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------



// Scrollbar
::-webkit-scrollbar
  width: $s-s
::-webkit-scrollbar-track
  opacity: 0
::-webkit-scrollbar-thumb
  min-height: $s-xxl
  background-color: $text-faded
  background-clip: padding-box
  border: 4px solid transparent
  border-radius: 16px

option
  color: $c-white
  background: $c-pickled-bluewood-blue
.sidebar
  z-index: $z-dropdown !important
  overflow-y: auto !important
  @include desktop
    overflow-y: visible !important

.datePicker
  li
    color: $text-faded

.affiliate
  color: $c-burning-orange