
@use '~@/assets/sass/design.sass' as *;

.multiSelectContainer {
    height: auto;
    padding-right: 0;
    overflow: hidden;
    cursor: pointer;
}

.selected {
  &Container {
    display: grid;
    grid-template-columns: auto 20px;
    align-items: center;
  }

  &Item {
    display: inline-block;
    padding: $s-xxs;
    margin: 0 $s-xxs $s-xxs 0;
    font-size: 10px;
    border: 1px solid $c-boulder-grey;
    border-radius: 6px;

    &Inner {
      display: flex;
      align-items: center;
    }
  }

  &Alternative {
    color: $text-faded;
  }
}

.removeButton {
  display: inline-flex;
  margin-left: $s-xxs;
}

.menu {
  display: none;
  max-height: 200px;
  padding: 0;
  margin: $base-spacing 0 0;
  overflow: auto;

  &.opened {
    display: block;
  }

  &Icon {
    width: 12.5px;
    line-height: unset;

    &Opened {
      transform: rotate(180deg);
    }
  }

  &Item {
    position: relative;
    display: block;
    padding: $s-xs;
    list-style: none;

    &::before {
      @include fill;

      z-index: -1;
      content: '';
      background: $c-acc-green;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    &:hover::before {
        opacity: 1;
    }

    &.active {
      background: transparentize($c-acc-green, 0.8);
    }
  }
}
