
@import '~@/assets/sass/design.sass'

.icon
  &::before
    display: inline-block
  &xs
    font-size: $s-xs
  &s
    font-size: $s-s
  &m
    font-size: $s-m
  &l
    font-size: $s-l
  &xl
    font-size: $s-xl
  &xxl
    font-size: $s-xxl
  &xxxl
    font-size: $s-xxxl
