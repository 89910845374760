
@import '~@/assets/sass/design.sass'

.modal.show
  background-color: transparentize($c-black, 0.7)
.modal.fade
  .modalCentered
    top: 50%
    margin: 0 auto
    transform: translate(0, -50%)
.modalBody
  padding: 0
.modalContent
  background: $c-ebony-clay
