
@import '~@/assets/sass/design.sass'

.item
  position: relative
  display: block
  max-width: 100%
  max-height: 100%
  margin: 0 auto
  opacity: 0
  transition: opacity .6s ease-in-out
.spinner
  opacity: 0.8
  transition: opacity .6s ease-in-out
  &::before
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    content: ''
    background: $green-blue-purple-gradient
    animation: fade 2s ease-in-out infinite alternate-reverse
.wrap
  display: flex
  align-items: center
  width: 100%
  max-height: 100%
  margin: 0
  text-align: center
  &.loaded
    .item
      opacity: 1
    .spinner
      pointer-events: none
      opacity: 0
  &.fill
    overflow: hidden
    .item
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &.fit
    .item
      object-fit: contain
      object-position: center
.noImage
  position: relative
  display: inline-block
  &Icon
    width: 150px
    max-width: 100%
    height: 100%
    opacity: 0.5
  .label
    margin-top: $base-spacing
.background
  position: absolute
  top: 0
  right: 0
  left: 0
  height: 100%
  background-position: center
  background-size: cover


@keyframes fade
  from
    opacity: 0.3
  to
    opacity: 0.7
