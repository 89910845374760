
.alert
  position: fixed
  z-index: 10000
  &[data-notify='container']
    width: 480px
    cursor: pointer
  &.center
    right: 0
    left: 0
    margin: 0 auto
  &.left
    left: 20px
  &.right 
    right: 20px
