
@use '~@/assets/sass/design.sass' as *;

.datatableLight {
	overflow: hidden;
	border-radius: 6px;

	th, td {
		&:first-child {
			padding-left: $base-spacing;
		}

		&:last-child {
			padding-right: $base-spacing;
		}
	}

	&.tableDark {
		background: transparent;
	}

	&.tableStriped {
		background: $c-pickled-bluewood-blue;
		border: none;

		tbody tr td {
			border: none;
		}

		th {
			border: none;
		}
	}

	&.tableHover tbody tr:hover {
		background: $c-san-juan-blue;
	}

	.headerItem {
		height: $table-header;
		background: lighten($c-pickled-bluewood-blue, 2%);
	}

	.title {
		color: $c-white;

		&:hover {
			color: $c-acc-green;
		}
	}

	.icon {
		&Edit {
			color: $c-acc-blue-dark;
		}

		&Delete {
			color: $c-acc-red;
			cursor: pointer;
		}
	}

	.published {
		color: $c-burning-orange;
		text-align: center;

		&.isPublished {
			color: $c-acc-green;
		}
	}

	.photo {
		position: relative;
		z-index: 1;
		min-width: 100%;
		min-height: 100%;
		border-radius: 0 !important;

		&Container {
			position: relative;
			width: $s-xl;
			height: $s-xl;
			overflow: hidden;
			border-radius: 50%;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
				width: 100%;
				height: 100%;
				content: '';
				background: $green-blue-purple-gradient;
				opacity: 0.3;
			}
		}
	}

	.footer {
		padding: 0;
	}

	.footerItem {
		background: lighten($c-pickled-bluewood-blue, 2%);
	}
}
