
@import '~@/assets/sass/design.sass'

.container
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
.content
  width: 370px
  margin: auto
.header
  margin-bottom: $base-spacing
.logo
  width: $s-xxl
  margin: $s-l auto
  text-align: center
.title
  margin: 0
  font-size: $s-l
  text-align: center

.button
  margin-top: $base-spacing
