
@import '~@/assets/sass/design.sass'

.listTablePagination
	margin-top: $base-spacing
	.pageItem
		&.selected
			display: block
			padding: 0 0.6875rem
			margin-left: -0.0625rem
			line-height: 30px
			background: $c-acc-green
	.pageLink
		height: 30px
.paginationContainer
	display: flex
	align-items: center
	justify-content: space-between
.total
	text-transform: initial
	&Number
		color: $c-acc-green
