
@import '~@/assets/sass/design.sass'

$outset-spacing: $base-spacing / 2

.label
  z-index: $z-page
  display: inline-block
  margin: 0
  color: $c-white
  border-radius: 15px
  box-shadow: $base-shadow
  &Text
    font-weight: bold
  &.float
    position: absolute

  // Sizes
  &.small
    padding: 3px 9px
    font-size: 9px
  &.medium
    padding: $base-spacing / 3 $base-spacing


  // Positions
  &.top
    top: 0
    bottom: unset
    &.outset
      top: -$outset-spacing
    &.inset
      top: $outset-spacing
  &.right
    right: 0
    left: unset
    &.outset
      right: -$outset-spacing
    &.inset
      right: $outset-spacing
  &.bottom
    top: unset
    bottom: 0
    &.outset
      bottom: -$outset-spacing
    &.inset
      bottom: $outset-spacing
  &.left
    right: unset
    left: 0
    &.outset
      left: -$outset-spacing
    &.inset
      left: $outset-spacing

  // Colors
  &.green
    background: $c-acc-green
  &.pink
    background: $c-acc-pink
  &.blue
    color: $c-ebony-clay-gray
    background: $c-acc-blue
  &.gradientGreen
    color: $c-ebony-clay-gray
    background: $green-grey-gradient
  &.gradientBlue
    background: $green-blue-purple-gradient
  &.gradientPink
    background: $pink-blue-gradient

  // Shape
  &.circle
    padding: 0
    vertical-align: middle
    border-radius: 50%
    &.small
      width: 40px
      height: 40px
      line-height: 40px
