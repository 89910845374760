
@import '~@/assets/sass/design.sass'

.button
  &Inner
    position: relative
    z-index: 1
.btn
  &Fill
    width: 100%
  &gradient
    position: relative
    background: transparent
    &::before
      position: absolute
      top: 0
      left: 0
      z-index: 0
      width: 100%
      height: 100%
      content: ''
  &green
    &::before
      background: $green-gradient
  &blue
    &::before
      background: $green-blue-purple-gradient
