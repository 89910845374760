
.notifications
  .list-enter-active
    transition: transform 0.2s ease-in, opacity 0.4s ease-in
  .list-leave-active
    transition: transform 1s ease-out, opacity 0.4s ease-out
  .list-enter
    opacity: 0
    transform: scale(1.1)
  .list-leave-to
    opacity: 0
    transform: scale(1.2, 0.7)
