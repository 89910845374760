
@import '~@/assets/sass/design.sass'

$slider-distance: 4px
$slider-size: $s-xl

.slider
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  cursor: pointer
  background-color: $c-san-juan-blue
  transition: .3s
  &::before
    position: absolute
    bottom: 4px
    left: 4px
    width: ($slider-size / 2) - $slider-distance
    height: ($slider-size / 2) - $slider-distance
    content: ""
    background-color: white
    transition: .3s

input
  &:checked + .slider
    background: $green-blue-purple-gradient
  &:checked + .slider::before
    transform: translateX(($slider-size / 2) - $slider-distance)

.switch
  position: relative
  display: inline-block
  width: $slider-size
  height: ($slider-size / 2) + $slider-distance
  input
    width: 0
    height: 0
    opacity: 0

.slider.round
  border-radius: ($slider-size / 2) + $slider-distance
  &::before
    border-radius: 50%
