
@import '~@/assets/sass/design.sass'

.list
  padding: 0
  list-style: none
.head
  padding: $base-spacing
.item
  padding: $base-spacing
  background: $blue-gradient
  border-radius: 8px
  &Inner
    display: grid
    grid-template-columns: 60px auto 60px 60px 60px
    grid-gap: $base-spacing
    align-items: center
    &.extended
      grid-template-columns: 60px 120px auto 60px 60px 60px

.item~.item
  margin-top: $base-spacing
.grip
  cursor: move
.photo
  position: relative
.title
  color: $c-white
  &:hover
    color: $c-acc-green
.icon
  &Edit
    color: $c-acc-blue-dark
  &Delete
    color: $c-acc-red
    cursor: pointer
  &Image
    @include center
